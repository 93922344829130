<template>
  <van-row class="searchTrain">
    <!--头部日历-->
    <van-sticky>
      <div class="head-background">
        <SliderDate @changeDate="calendarConfirm" :cur="orderInfo.goBackDate[0]" @open="show_calendar = true" />
      </div>
    </van-sticky>

    <!-- 列表 -->
    <van-skeleton :row-width="skeletonArr" :row="4*7" :loading="listLoad&&!(0 in showList)">
      <van-empty v-show="!(0 in showList)" description="暂无车票，请尝试其他车站。" />
      <div style="height: calc(100vh - 100px);overflow:hidden" v-if="0 in showList">
        <TicketList :list="showList" :listLoad="listLoad" @childClick="chooseTicket($event)" />
      </div>
      <span v-show="listLoad" class="list-text loading-text" :loading="listLoad">加载中...</span>
    </van-skeleton>

    <!--筛选bar-->
    <van-tabbar style="border:1px solid #eee" v-model="activenav" safe-area-inset-bottom>
      <van-tabbar-item name="early" class="navicon" icon-prefix="icon" icon="naozhong" replace @click="sortList()">提早
      </van-tabbar-item>
      <van-tabbar-item name="money" class="navicon" icon-prefix="icon" icon="feiyongbaohan" replace @click="sortList()">
        省钱</van-tabbar-item>
      <van-tabbar-item name="time" class="navicon" icon-prefix="icon" icon="shanguangdengdakai" replace
        @click="sortList()">省时</van-tabbar-item>
      <van-tabbar-item :dot="0 in ticket_filter_activeIds" name="filter" class="navicon" icon-prefix="icon"
        icon="shaixuan" replace @click="ticket_filter_show = true">筛选</van-tabbar-item>
    </van-tabbar>

    <!--日期选择-->
    <van-calendar v-model="show_calendar" :show-confirm="false" confirm-disabled-text="请选择出发时间"
      :default-date="new Date(orderInfo.goBackDate[0])" position="right" :max-date="maxDate" :round="false"
      @confirm="calendarConfirm" />

    <!--筛选选项卡-->
    <van-action-sheet v-model="ticket_filter_show" title="筛选" safe-area-inset-bottom :close-on-click-overlay="true">
      <div class="tab_bar_filter_content">
        <van-tree-select :items="ticket_filter_items" :active-id.sync="ticket_filter_activeIds"
          :main-active-index.sync="ticket_filter_activeIndex" />
      </div>
      <van-row type="flex" justify="space-around" align="center" style="margin-bottom: 10px">
        <van-button style="width: 100px" plain type="info" @click="ticket_filter_activeIds=[]">清空</van-button>
        <van-button style="width: 100px" type="info" @click="tabbar_filter_confirm">确认</van-button>
      </van-row>
    </van-action-sheet>

  </van-row>
</template>

<script>
import { mapGetters } from "vuex";
import TicketList from "./TicketList";
import { init } from "@/services/list";
import SliderDate from "@/components/SliderDate";
import moment from "moment";
import { IsNormal } from "@/services/train";
import { AddDate } from "@/services/date.js";
import SkeletonArr from "@/common/Skeleton.js";
import {
  TrainServiceProvider,
  MaxSelectDate,
  TrainAsyncList,
  TrainDefaultSort
} from "@/common/config";
export default {
  props: ["hideCheck"],
  components: { TicketList, SliderDate },
  computed: {
    ...mapGetters(["orderInfo", "personList"]),

    // 查询接口用的参数
    query: function() {
      return {
        fromCityName: this.orderInfo.trainFromStation,
        fromCityCode: this.orderInfo.trainFromStationCode,
        toCityName: this.orderInfo.trainToStation,
        toCityCode: this.orderInfo.trainToStationCode,
        fromDate: moment(this.orderInfo.goBackDate[0]).format("YYYY-MM-DD"),
        corp: null
      };
    },

    // 底部筛选 - 选项
    ticket_filter_items: function() {
      let selectList = [
        {
          text: "车次类型",
          children: [
            {
              text: "高铁 / 动车", // 名称
              id: "类型:动车" // id，作为匹配选中状态的标识符
            },
            {
              text: "普通",
              id: "类型:普通"
            }
          ]
        }
      ];
      let start = [],
        end = []; // 出发站点、终到站点、服务商
      this.showList.forEach(element => {
        start.push(element.fromStationName);
        end.push(element.toStationName);
      });
      selectList.push({
        text: "出发站",
        children: [...new Set(start)].map(item => {
          return {
            text: item,
            id: "出发站:" + item
          };
        })
      });
      selectList.push({
        text: "终到站",
        children: [...new Set(end)].map(item => {
          return {
            text: item,
            id: "终到站:" + item
          };
        })
      });
      selectList.push({
        text: "服务商",
        children: TrainServiceProvider.map(item => {
          return {
            text: item,
            id: "服务商:" + item
          };
        })
      });
      return selectList;
    },

    // 已选供应商
    selectedCorps: function() {
      let res = this.ticket_filter_activeIds.filter(e =>
        TrainServiceProvider.includes(e)
      );
      return 0 in res ? res : TrainServiceProvider;
    }
  },

  // 每次进入页面重置下window.title
  activated() {
    this.setTabTitle();
    let dom = document.getElementsByClassName("v-virtual-scroll")[0];
    if (dom) dom.scrollTop = this.tempTop;
    if (!this.listLoad) this.onRefresh(false); // 如果这波请求完成了才允许，防止跟mounted一起触发
  },

  // 离开记录位置
  deactivated() {
    let dom = document.getElementsByClassName("v-virtual-scroll")[0];
    if (dom) this.tempTop = dom.scrollTop;
  },

  async mounted() {
    switch (this.orderInfo.trainType) {
      case "高铁 / 动车":
        this.ticket_filter_activeIds.push("类型:动车");
        break;
      case "普通":
        this.ticket_filter_activeIds.push("类型:普通");
    }
    this.setTabTitle();
    this.onRefresh(false);
  },
  data() {
    return {
      config_renderType: TrainAsyncList, // 列表渲染方式 - config.js
      skeletonArr: SkeletonArr, // 骨架数据
      maxDate: new Date(AddDate(new Date(), MaxSelectDate)), // 可选日期范围 - config.js
      activenav: TrainDefaultSort, // 底部四个按钮导航 变蓝 - config.js
      listLoad: true, // 列表加载状态
      showList: [], // 用来展示的列表
      currentlist: init(), // 缓存对象

      tempTop: 0,
      show_calendar: false,

      //筛选bar中的详细筛选
      ticket_filter_show: false, // 筛选 - 开关
      ticket_filter_activeIds: [], // 筛选 - 选中的id
      ticket_filter_activeIndex: 0 // 筛选 - 变红的那个
    };
  },
  methods: {
    // 设置浏览器顶部的中文名
    setTabTitle() {
      window.setTitle(
        (IsNormal() ? "" : "改签：") +
          this.orderInfo.trainFromStation +
          " - " +
          this.orderInfo.trainToStation
      );
    },

    // 重置列表 reset: 是否清空列表源数据
    onRefresh(reset = true) {
      // 重置列表
      if (reset) {
        this.showList = [];
        this.currentlist.resetList();
      }
      // 补齐缺失前先对其supplier
      else this.currentlist.itsSupplierNum();
      this.listLoad = true;
      this.currentlist.update("train", this.query, this.selectedCorps);
    },

    // 筛选列表排序
    sortList() {
      switch (this.activenav) {
        // 省钱排序
        case "money":
          this.showList.sort((a, b) => {
            return (
              parseInt(a.displayPrice || a.displaySeat[0].seatPrice) -
              parseInt(b.displayPrice || b.displaySeat[0].seatPrice)
            );
          });
          break;
        //省时排序
        case "time":
          this.showList.sort((a, b) => {
            return (
              a.runTime.replace(/[^0-9]/gi, "") -
              b.runTime.replace(/[^0-9]/gi, "")
            );
          });
          break;
        // 提早排序
        default:
          this.showList.sort((a, b) => {
            if ((a.arriveDays || "0") === (b.arriveDays || "0")) {
              let a_time = parseInt(a.startTime.replace(":", ""));
              let b_time = parseInt(b.startTime.replace(":", ""));
              return a_time - b_time;
            } else {
              return a.arriveDays - b.arriveDays;
            }
          });
      }
      let dom = document.getElementsByClassName("v-virtual-scroll")[0];
      if (dom) dom.scrollTop = 0; // 滚回顶部
      this.listLoad = false;
    },

    // 日历选择日期
    calendarConfirm(date) {
      this.orderInfo.goBackDate.splice(0, 1, date);
      this.$store.dispatch("train/setOrderInfo", this.orderInfo);
      this.show_calendar = false;
      this.onRefresh(false);
    },

    //筛选filter 确认
    tabbar_filter_confirm() {
      let trainClass = ["G", "C", "D"];
      let corp = [];
      let station = [];
      let type = [];
      this.ticket_filter_activeIds.forEach(e => {
        if (e.includes("服务商")) corp.push(e.split(":")[1]);
        if (e.includes("出发站")) station.push(e.split(":")[1]);
        if (e.includes("类型")) type.push(e.split(":")[1]);
      });
      let arr = [];
      this.currentlist.list.forEach(element => {
        let f = true;
        if (corp.length > 0) f = f && corp.includes(element.corp);
        if (station.length > 0)
          f = f && station.includes(element.fromStationName);
        if (type.length > 0)
          f =
            f &&
            type.includes(
              trainClass.includes(element.trainCode.slice(0, 1))
                ? "动车"
                : "普通"
            );
        if (f) arr.push(element);
      });
      this.showList = arr;
      this.ticket_filter_show = false;
    },

    // 选好票啦~！
    chooseTicket(ticketInfo) {
      //如果不能买票，且没座了，就啥也别干
      if (ticketInfo.canBuyNow || ticketInfo.displayHasSeat) {
        return; //啥也不干
      } else if (
        !this.hideCheck &&
        this.$route.name != "searchTrainChange" &&
        ticketInfo.displaySeat.filter(item => {
          return item.seatNum >= this.personList.length;
        }).length == 0
      ) {
        this.$dialog
          .confirm({
            title: "标题",
            message: "当前余票数量较少，无法保证全部人员有座，是否继续？"
          })
          .then(() => {
            this.$store.dispatch("train/setTicket", ticketInfo);
            this.$emit("chooseTicket", this.active);
          });
      } else {
        this.$store.dispatch("train/setTicket", ticketInfo);
        this.$emit("chooseTicket", this.active);
      }
    }
  },

  watch: {
    "currentlist.check": {
      handler: function() {
        // 异步加载
        if (this.config_renderType == "async") {
          if (this.currentlist.supplierNum >= this.selectedCorps.length)
            this.listLoad = false; // 所有请求完成更改”加载状态“
          this.showList = this.currentlist.list; // 有返回就往列表里塞
        }

        // 同步加载
        if (this.config_renderType == "sync") {
          if (
            this.currentlist.supplierNum >= this.selectedCorps.length &&
            this.listLoad
          ) {
            this.showList = this.currentlist.list;
            this.sortList(); // 所有请求完成调用排序
          }
        }
      }
    }
  }
};
</script>

<style lang="less" src="../../../styles/searchCommon.less" scoped></style>
<template>
  <!-- 火车列表 -->
  <v-virtual-scroll bench="4" ref="virtual" :items="list.concat([null])" :item-height="130" height="calc(100vh - 22px)">
    <template v-slot="{ item }">
      <!-- 最后一条 额外增加个提示 -->
      <div v-if="!item" class="last-item">到底了</div>

      <div v-else class="itembk list-outline" @click="chooseTicket(item)">
        <!-- 上半部分票信息 -->
        <van-row class="item-top" type="flex" justify="space-between" align="center">
          <van-col span="6">
            <van-row class="list-time">{{item.startTime}}</van-row>
            <van-row class="info-text list-text">{{item.fromStationName}}</van-row>
          </van-col>
          <van-col span="6" style="padding-top:6px" class="list-text-big">
            {{item.trainCode}}&nbsp;
            <el-tooltip class="item" effect="dark" content="可凭二代身份证直接进出站" placement="bottom-start" :hide-after="3000"
              @click.prevent.stop>
              <van-row class="info-text list-text" v-if="['C','G','D'].includes(item.trainCode.slice(0,1))" type="flex"
                justify="center" align="center" @click.prevent.stop>
                <van-icon class="iconfont" class-prefix="icon" name="idCard" />
              </van-row>
            </el-tooltip>
            <van-icon class="iconfont line" class-prefix="icon" name="icon_jipiaodancheng" />
            <van-row class="info-text list-text">{{item.runTime}}</van-row>
          </van-col>
          <van-col span="6">
            <!--因为+1天会影响到达时间的对齐显示，所以追加判断-->
            <template v-if="item.arriveDays!=='0'">
              <van-row type="flex" justify="top" style="justify-content:center" class="list-time">
                {{item.arriveTime}}
                <span class="text-sm"
                  style="font-weight: normal; font-family: Arial Black, Gadget, sans-serif">{{(!item.arriveDay||item.arriveDays==="0")?"":'+'+item.arriveDays}}</span>
              </van-row>
            </template>
            <!--因为+1天会影响到达时间的对齐显示，所以追加判断-->
            <template v-if="item.arriveDays==='0'">
              <van-row class="list-time">{{item.arriveTime}}</van-row>
            </template>
            <van-row class="info-text list-text">{{item.toStationName}}</van-row>
          </van-col>
          <van-col span="6">
            <van-row>
              <span style="color: red;">￥</span>
              <span style="color: red;font-size: 1.2rem">{{item.displayPrice||'- '}}</span>
              <span style="color: #acacac;font-size: .7rem">起</span>
            </van-row>
            <!--服务商标签-->
            <van-row type="flex" justify="center">
              <Logo :corp="item.corp" style="padding-top:0.2rem" />
            </van-row>
          </van-col>
        </van-row>

        <!-- 座位信息 -->
        <van-row type="flex" align="center" class="item-bottom">
          <template v-for="(seat,i) in item.displaySeat">
            <van-col v-if="seat.seatNum>9" span="6" :key="i">{{seat.seatType}}:充足</van-col>
            <van-col v-else-if="seat.seatNum>0" span="6" :key="i">{{seat.seatType}}:<span
                style="color: red">{{seat.seatNum}}张</span></van-col>
            <van-col v-else span="6" :key="i">
              <span style="color: #ccc">{{seat.seatType}}:无票</span>
            </van-col>
          </template>
        </van-row>

      </div>
    </template>
  </v-virtual-scroll>
</template>

<script>
import { VVirtualScroll } from "vuetify/lib";
import Logo from "@/components/Logo";
export default {
  name: "HotelTicketList",
  components: { VVirtualScroll, Logo },
  props: {
    list: Array, // 列表
    listLoad: Boolean
  },
  methods: {
    // 点击选择
    chooseTicket(data) {
      this.$emit("childClick", data);
    }
  }
};
</script>

<style lang="less" src="@/styles/itemCommon.less" scoped></style>
<style lang="less" scoped>
.list-outline {
  height: 122px;
  max-height: 122px;
}
</style>
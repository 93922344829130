import { render, staticRenderFns } from "./SearchTrainUI.vue?vue&type=template&id=5aa95776&scoped=true&"
import script from "./SearchTrainUI.vue?vue&type=script&lang=js&"
export * from "./SearchTrainUI.vue?vue&type=script&lang=js&"
import style0 from "../../../styles/searchCommon.less?vue&type=style&index=0&id=5aa95776&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.9.6@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5aa95776",
  null
  
)

export default component.exports